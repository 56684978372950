import axios from "axios";

export async function loginHandler(values) {
  const url = process.env.REACT_APP_BACKEND_URL;
  let loginResult = {
    success: false,
    message: "",
  };
  await axios
    .post(url + "login", {
      email: values.username,
      password: values.password,
      reference: window.location.hostname.toString().split(".")[0],
    })
    .then((res) => {
      if (res.data.STATUS) {
        const usernameEncoded = window.btoa(
          encodeURIComponent(values.username)
        );
        const passwordEncoded = window.btoa(
          encodeURIComponent(values.password)
        );
        const reference = encodeURIComponent(
          window.location.hostname.toString().split(".")[0]
        );

        let newURL;
        if (process.env.NODE_ENV !== "development") {
          newURL = new URL(
            process.env.REACT_APP_LANDING_LOGIN +
              `?u=${usernameEncoded}&p=${passwordEncoded}&r=${reference}`
          );
        } else {
          newURL = new URL(
            "https://localhost:3000/login-landing/" +
              `?u=${usernameEncoded}&p=${passwordEncoded}&r=${reference}`
          );
        }

        window.location.href = newURL;
        //window.open(newURL, "_blank");
        loginResult.success = res.data.STATUS;
      } else {
        loginResult.success = res.data.STATUS;
        loginResult.message = res.data.message;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return loginResult;
}
