import axios from "axios";
import { loginHandler } from "./loginHandler";

export async function registrationHandler(values) {
  let regResult = {
    success: false,
    message: "",
    username: "",
    password: "",
  };
  await axios
    .post(process.env.REACT_APP_BACKEND_URL + "new_register", {
      username: values.username,
      password: values.password,
      dob: values.dob,
      gender: values.gender,
      looking_for: values.lookingGender,
      reference: window.location.hostname.toString().split(".")[0],
    })
    .then(async (res) => {
      if (res.data.STATUS === 1) {
        console.log(res);
        await loginHandler({
          username: values.username,
          password: values.password,
        });
      } else {
        regResult.success = res.data.STATUS;
        regResult.message = res.data.message;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return regResult;
}
