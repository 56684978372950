import styles from "./App.module.css";
import { Header } from "./Header";
import { EntryFormContainer } from "./EntryFormContainer";
import { Container, Grid } from "@mui/material";
import React from "react";

function App() {
  return (
    <div className={styles.App}>
      <Container maxWidth="false">
        <div className={styles.headerWrap}>
          <Header />
        </div>
        <Grid container spacing={{ xl: 2, sm: 2, xs: 0 }}>
          <Grid item xl={7} lg={7} sm={7} xs={0}></Grid>
          <Grid
            item
            xl={4}
            lg={5}
            sm={5}
            xs={12}
            className={styles.appForm}
            container={true}
            sx={{ padding: 0, justifyContent: "right" }}
          >
            <EntryFormContainer />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default App;
