import React from "react";
import { EntryForm } from "./EntryForm";
import styles from "./entryformcontainer.module.css";

export function EntryFormContainer() {
  return (
    <div className={styles.entryFormContainer}>
      <h1 className={styles.formHeader}>MEET FUN SINGLES!</h1>
      <p className={styles.formDescription}>
        You perfect match in just a click away!
      </p>
      <EntryForm />
    </div>
  );
}
